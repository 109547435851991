<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            我的订单
        </div>
        <div class="vip-content-inner">

            <div class="order-det">
                <div class="row">
                    <div class="item">订单编号 : {{ orderInfo.orderNumber }}</div>
                    <div class="item">下单日期 : {{ orderInfo.createTime }}</div>
<!--                    <div class="item">订单状态 : {{ orderInfo.status==0?'处理中':orderInfo.status==1?'已确认':orderInfo.status==2?'待发货':orderInfo.status==3?'部分发货':orderInfo.status==4?'已发货':orderInfo.status==5?'部分收货':orderInfo.status==6?'已完成':orderInfo.status==7?'已取消':orderInfo.status==8?'退款中':orderInfo.status==9?'已退款':'-' }}</div>-->
<!--                    <div class="item">订单状态 : {{ orderInfo.statusName==0?'待支付':orderInfo.statusName==1?'待确认':orderInfo.statusName==2?'待发货':orderInfo.statusName==3?'部分发货':orderInfo.statusName==4?'已发货':orderInfo.statusName==5?'部分收货':orderInfo.statusName==6?'已收货':orderInfo.statusName==7?'已取消':orderInfo.statusName==8?'退款中':orderInfo.statusName==9?'已退款':orderInfo.statusName==10?'已完成':'' }}</div>-->
                    <div class="item">订单状态 : {{ orderInfo.statusName}}</div>
                    <div class="item">
                        <div class="g-btn small" @click="clickPrint">打印合同</div>
                    </div>
                </div>
                <h3 class="mab20">产品明细</h3>

                <div class="g-table-wrap">
                    <table class="g-table">
                        <thead>
                        <tr>
                            <td>序号</td>
                            <td>产品编号</td>
                            <td>产品名称</td>
                            <td width="100">品牌</td>
                            <td width="100">CAS号</td>
                            <td>包装规格</td>
                            <td>订购数量</td>
                            <td>成交价格</td>
                            <td>货期</td>
<!--                            <td  v-if="orderInfo.status!=0&&orderInfo.status!=1&&orderInfo.status!=2&&orderInfo.status!=7&&orderInfo.status!=8&&orderInfo.status!=9">发货信息</td>-->
                            <td >发货信息</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,index) in ordergoodsList" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>
                                {{item.goodsNumber}}
                            </td>
                            <td>
                                {{item.name}}
                            </td>
                            <td>
                                {{ item.brandName }}
                            </td>
                            <td>
                                <div class="c-1c8f8e cursorPointer ftwt" @click="goSearchResult(item.casNumber)">{{ item.casNumber }}</div>
                            </td>
                            <td>
                                {{ item.specification }}
                            </td>
                            <td>{{ item.quantityPurchase }}</td>
                            <td>{{ item.amount }}</td>
                            <td>
                                现货
                            </td>
                            <td v-if="item.status!=0&&item.status!=1&&item.status!=2&&item.status!=7&&item.status!=8&&item.status!=9">
                                <a :href="`#/goodsDeliveryInfo?id=${item.orderId}&&goodsId=${item.goodsId}`" class="blue2 cup">
                                    发货详情
                                </a>
                            </td>
                            <td v-else>未发货</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="mat20 text_cen" v-if="ordergoodsList.length==0">暂无数据</div>
                </div>
                <div class="display">
                    <div v-if="orderInfo.payStatus==0 && !isexpire" class="box wid50 mat20">
<!--                    <div v-if="true" class="box wid50 mat20">-->
                        <div class="box-tit mab10">
                            <h3 class="mab10">确认支付方式</h3>
                            <div class="pay-type" style="margin-left: 0 !important;">
                                <div class="item" v-if="payIndex === 0" :class="payIndex === 0 ? 'active' : '' " @click="payIndex = 0,orderInfo.payType = 1">
                                    <div class="icon">
                                        <img class="def" src="@/assets/img/pay1.png" alt="">
                                        <img class="active" src="@/assets/img/pay1-a.png" alt="">
                                    </div>
                                    个人支付
                                </div>
                                <div class="item" v-if="payIndex === 1" :class="payIndex === 1 ? 'active' : '' " @click="payIndex = 1,orderInfo.payType = 0">
                                    <div class="icon">
                                        <img class="def" src="@/assets/img/pay2.png" alt="">
                                        <img class="active" src="@/assets/img/pay2-a.png" alt="">
                                    </div>
                                    对公打款
                                </div>
                                <div class="item" v-if="payIndex === 2" :class="payIndex === 2 ? 'active' : '' " @click="payIndex = 2,orderInfo.payType = 3">
                                    <div class="icon">
                                        <img class="def" src="@/assets/img/pay3.png" alt="">
                                        <img class="active" src="@/assets/img/pay3-a.png" alt="">
                                    </div>
                                    余额抵扣
                                </div>
                            </div>
                        </div>
                        <div class="tips pay1 displayAli_cen" v-if="payIndex === 0">
                            <div class="item mar-15 cursorPointer display juzhong" v-if="onlinePayType==1" :class="onlinePayType==1?'c-f37500':''" @click="onlinePayType=1,orderInfo.payType=1">
                                <img src="@/assets/img/wx-pay.png" alt="">
                                微信支付
                            </div>
                            <div class="item cursorPointer display juzhong" v-if="onlinePayType==2" :class="onlinePayType==2?'c-f37500':''" @click="onlinePayType=2,orderInfo.payType=2">
                                <img src="@/assets/img/ali-pay.png" alt="">
                                支付宝支付
                            </div>
                        </div>
                        <div class="tips pay2 c-666 fts14 lineH1_8" v-if="payIndex === 1">
                            <h3>请汇款至 <em>银行账户</em></h3>
                            <p>
                                <span>账户名称:</span>
                                天津一方供应链管理有限公司
                            </p>
                            <p>
                                <span>帐号:</span>
                                442100100100070663
                            </p>
                            <p>
                                <span>开户行:</span>
                                兴业银行天津自由贸易试验区分行
                            </p>
                            <p>
                                <span>银行行号:</span>
                                309110018259
                            </p>
                        </div>
                        <div class="tips pay3 c-666 fts14 lineH1_8" v-if="payIndex === 2">
                            <h3>账户余额</h3>
                            <p class="displayAli_cen">
                                <span class="mar-15">当前预付款余额</span>
                                <em style="color: #ea6100;font-size: 16px;font-weight: 700;">{{ moneyTotal }}</em>
                            </p>
                        </div>
                        <div class="item mat20" @click="clickPay(orderInfo.payType)">
                            <div class="g-btn small">确认支付</div>
                        </div>
                    </div>
                    <div v-else class="wid50"></div>
                    <div class="total-count wid50">
                        <p>
                            <span class="label">产品合计：</span>
                            <span class="con">¥{{orderInfo.amount}}</span>
                        </p>
                        <p>
                            <span class="label">运费：</span>
                            <span class="con">¥{{orderInfo.freight}}</span>
                        </p>
                        <p>
                            <span class="label fwb">订单总额：</span>
                            <span class="con red">¥{{orderInfo.amount+orderInfo.freight}}</span>
                        </p>
                    </div>
                </div>


                <div class="order-info">
                    <h3>收货信息</h3>
                    <div class="item">
                        <p>
                            <span>收货人：</span>{{ orderInfo.deliveryUsername }}
                        </p>
                        <p>
                            <span>收货手机号码：</span>{{ orderInfo.deliveryPhone }}
                        </p>
                    </div>
                    <div class="item">
                        <p>
                            <span>收货地址：</span>
                            {{orderInfo.deliveryRegionCodeName + orderInfo.deliveryAddress }}
                        </p>
                    </div>
                    <h3>收票信息</h3>
<!--                    <div class="item">-->
<!--                        <p>-->
<!--                            <span>收票人：</span>{{ orderInfo.receiptUsername }}-->
<!--                        </p>-->
<!--                        <p>-->
<!--                            <span>收票手机号码：</span>{{ orderInfo.receiptPhone }}-->
<!--                        </p>-->
<!--                    </div>-->
                    <div class="item">
                        <p>
<!--                            <span>收票地址：</span>{{orderInfo.receiptRegionCodeName + orderInfo.receiptAddress }}-->
                            <span>收票邮箱：</span>{{orderInfo.receiptAddress }}
                        </p>
                    </div>
                    <h3>开票信息</h3>
                    <div class="item">
                        <p>
                            <span>开票类型：</span>{{orderInfo.invoiceType==1?'专票':'普票'}}
                        </p>
                    </div>
                    <div class="item">
                        <p>
                            <span>单位名称：</span>{{ orderInfo.invoiceCompanyName }}
                        </p>
                    </div>
<!--                    <div class="item">-->
<!--                        <p>-->
<!--                            <span>纳税人识别码：</span>{{ orderInfo.invoiceTaxpayerIdentificationNumber }}-->
<!--                        </p>-->
<!--                    </div>-->
<!--                    <div class="item">-->
<!--                        <p>-->
<!--                            <span>注册地址：</span>{{ orderInfo.invoiceRegisteredAddress }}-->
<!--                        </p>-->
<!--                    </div>-->
<!--                    <div class="item">-->
<!--                        <p>-->
<!--                            <span>注册电话：</span>{{ orderInfo.invoiceRegistrationTelephone }}-->
<!--                        </p>-->
<!--                    </div>-->
<!--                    <div class="item">-->
<!--                        <p>-->
<!--                            <span>开户银行：</span>{{ orderInfo.invoiceDepositBank }}-->
<!--                        </p>-->
<!--                    </div>-->
<!--                    <div class="item">-->
<!--                        <p>-->
<!--                            <span>银行账户：</span>{{ orderInfo.invoiceBankAccountNumber }}-->
<!--                        </p>-->
<!--                    </div>-->
                    <div v-if="orderInfo.payStatus==1">
                        <h3>支付方式：</h3>
                        <div class="item">
                            <p>
                                {{ orderInfo.payType==0?'对公打款':orderInfo.payType==1?'微信支付':orderInfo.payType==2?'支付宝支付':orderInfo.payType==3?'余额支付':'账期' }}
                            </p>
                        </div>
                    </div>

                    <h3>备注：</h3>
                    <div class="item">
                        <p>
                            {{ orderInfo.remarks?orderInfo.remarks:'无' }}
                        </p>
                    </div>
                </div>
                <div class="order-tips">
                    <h3>本订货确认单与天津一方供应链销售合同具备同等法律效力</h3>
                    <ol>
                        <li>发货时间和方式：发货时间详见预计发货期。如卖方因不可抗力原因无法供货，卖方将及时通知买方，双方将视情况决
                            定部分不履行、 全部不履行或延期履行；
                            如供货期限届满日为国内外重大节日或国家法定节假日，则供货期限顺延；若因缺货导致延期交货或无法供货，卖方将及时告知买方，并不视为卖方违约。
                        </li>
                        <li>验收标准及异议期间：买方应以产品制造商的标准作为验收标准。如产品有任何内在质量上的瑕疵，买方应在收货后7天内提出书面异议
                            ；对于货物外在质量瑕疵，如：货物包装破损、泄漏，数量短缺、产品编号、品名、包装规格及金额与合同约定不符等，买方应在收到货物之日起2天
                            内向卖方提出书面异议。买方逾期不提出任何异议，视为货物符合其要求，卖方不承担任何责任。
                        </li>
                        <li>不可抗力：因火灾、洪水、风暴、爆炸、恐怖事件、战争、政府行为或任何其他卖方无法预见、无法避免和无法克服的事件造成延期交货或
                            无法交货的，卖方不承担任何责任。
                        </li>
                        <li>责任限制：在适用法律允许的最大限度内，卖方不对以任何方式由本合同、卖方产品或使用卖方产品引起的或与此有关的任何利润或收入损
                            失或其他后果性损失、附随性损失、特殊损失、惩罚性损失或任何其他间接损失或损害承担责任。在适用法律允许的最大限度内，卖方的最高赔偿
                            限额为买方就所涉及的卖方产品实际支付给卖方的价格。
                        </li>
                        <li>争议解决：本合同在执行过程中如买卖双方发生争议，双方应友好协商解决。双方协商不能解决时，任何一方均有权向所在地仲裁部门申请
                            仲裁，或向人民法院起诉。
                        </li>
                        <li>
                            其他约定事项：本销售确认单即为买方与本公司之间就上述产品达成的销售合同，双方不再另行签署纸质合同。
                        </li>
                    </ol>
                </div>
            </div>
        </div>

        <el-dialog
            title="支付"
            :visible.sync="dialogVisiblePay"
            width="50%">
            <div class="display juzhong flex-column mab20" v-if="onlinePayType==1">
                <div class="mab20 fts16">请使用微信扫描二维码进行支付  {{countdown}}s</div>

                <div id="payType2Code" ref="payType2Code" class=""></div>
            </div>
            <div v-else>
            </div>

<!--            <div class="displayAli_cen">-->
<!--                <div class="g-btn small" style="margin: 0 auto;" @click="getorderDetail(),closePay()">已支付</div>-->
<!--                <div class="g-btn small" style="margin: 0 auto;" @click="closePay">未支付</div>-->
<!--            </div>-->

        </el-dialog>

<!--        支付宝支付-->
        <div ref="alipayWap" v-html="aliPayInfo"></div>

    </div>
</template>

<script>
import {orderInfoDetail,orderInfoNumber, ordergoodslist, systemConfig, orderInfoPay, orderInfoSave} from "@/api/transaction";
import {userDetail} from "@/api/account";

import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'
import Bus from "@/utils/bus";
import {getToken} from "@/utils/auth";

export default {
    name: "goodsOrderDetail",
    data() {
        return {
            orderId:'', // 订单id
            orderNumber:'', // 订单号
            orderInfo:'', // 订单信息
            ordergoodsList:[], // 订单商品列表

            payIndex:0, // 支付方式
            onlinePayType:1, // 线上支付方式
            isexpire:true, // 订单未支付是否过期 true已过期 false未过期

            moneyTotal:0, // 用户余额

            dialogVisiblePay:false, // 二维码弹窗
            aliPayInfo:'', // 支付宝支付信息

            countdown:30, // 倒计时
        }
    },
    created() {
        // this.LoadList();
        // //取得地址信息
        // let orderOnInfo=this.$route.query.orderNo
        // console.log("orderOnInfo====",orderOnInfo)
        // if(orderOnInfo != null && orderOnInfo!='000'){
        //     this.$message.success("支付成功")
        //     //清除地址栏
        //     let path = this.$route.path; //先获取路由路径
        //     this.$router.push(path); //再跳转路由路径，query参数没带过去，所以被清除了
        // }
    },

    mounted() {
        if(this.$route.query){
            console.log(this.$route.query)
            this.orderId=this.$route.query.id
            this.getorderDetail() //获取订单详情
            this.getorderGoods() //获取订单商品
            this.getuserDetail() // 获取用户信息

        }

    },
    methods:{
        //点击打印合同
        clickPrint(){
            // window.location.href = 'https://chn.yifanglab.com/api/blade-goods/goods-info/export-cart-template?blade-auth='+getToken();
            window.location.href = 'https://chn.yifanglab.com/api/blade-trade/order-info/order-contract?id='+this.orderId+'&blade-auth='+ getToken();

        },
        //生成二维码
        creatQrCode(printMess) {
            var qrcode = new QRCode(this.$refs.payType2Code, {
                text: printMess,
                width: 200,
                height: 200,
                colorDark: '#000000',
                colorLight: '#ffffff',
                render: 'table',
                correctLevel: QRCode.CorrectLevel.H
            })
            console.log(qrcode)
            return
        },
        // 关闭微信支付弹窗
        closePay(){
          this.dialogVisiblePay=false
            this.countdown=30
            this.getorderDetail() // 重新查询订单
            document.getElementById('payType2Code').innerHTML = ""; // 重置二维码
        },

        // 提示
        message(type,title,duration){
            this.$message({
                type: type,
                message: title,
                duration: duration || 2000
            });
        },

// 去搜索页
        goSearchResult(casNumber){
            this.$router.push({path:'/SearchResult',query: {casNumber:casNumber}}) //
        },

    //    -------------接口请求
    //    获取订单详情
        getorderDetail(){
            let param={
                customerId:localStorage.getItem('customId'),
                id:this.orderId
            }
            orderInfoDetail(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    // console.log('订单列表',res.data.data)
                    let data=res.data.data
                    data.amount=Number(data.amount)
                    data.freight=Number(data.freight)
                    data.createTime1=data.createTime
                    data.createTime=data.createTime.slice(0,10)
                    this.orderInfo=res.data.data
                    this.payIndex=this.orderInfo.payType==1||this.orderInfo.payType==2?0:this.orderInfo.payType==0?1:2
                    this.onlinePayType=this.orderInfo.payType
                    this.getfreight() //查询未支付订单自动取消

                    this.orderNumber=data.orderNumber
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            }).catch(err=>{
                this.loading=false
            })
        },
        //    根据订单号获取订单详情
        getorderNumDetail(){
            let param={
                customerId:localStorage.getItem('customId'),
                orderNumber:this.orderNumber
            }
            orderInfoNumber(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    // console.log('订单列表',res.data.data)
                    let data=res.data.data
                    if(data.payStatus==1){ // 支付成功
                        this.$message({
                            type: 'success',
                            message: '支付成功',
                            duration: 2000
                        });
                        this.closePay() // 关闭弹窗
                        clearInterval(this.timeInterval)

                    }

                }
            })
        },

        //    获取订单商品
        getorderGoods(){
            let param={
                // customerId:localStorage.getItem('customId'),
                orderId:this.orderId
            }
            ordergoodslist(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    // console.log('订单列表',res.data.data)
                    res.data.data.forEach(i=>{
                        // i.describeText=this.$utils.showHtml(i.describeText)
                    })
                    this.ordergoodsList=res.data.data
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            })
        },
        //    查询系统配置  未支付订单自动取消时间
        getfreight(){
            // postage_setting查运费 unpaid_orders_automatically_cancelled未支付订单自动取消时间
            systemConfig({paramCode:'unpaid_orders_automatically_cancelled'}).then((res) => {
                if (res.data.code==200) {
                    // console.log(res.data.data)
                    let data=res.data.data
                    let newtime=new Date().getTime()
                    let ordertime=new Date(this.orderInfo.createTime1).getTime()
                    console.log(newtime - ordertime)
                    console.log(data.paramValue * 60 *1000)
                    this.isexpire= (newtime - ordertime) > (data.paramValue * 60 *1000)
                    // this.isexpire= false
                    console.log(this.isexpire)

                }

            })
        },

    //    点击支付
        clickPay(type){
            let payType=this.orderInfo.payType
            payType=type
            console.log(this.orderInfo.payType)
            let params={
                orderId:this.orderId,
                payType:payType //支付方式：0对公，1微信，2支付宝，3余额
            }
            if(payType==2){ // 支付宝支付
                this.$loading({
                    lock: true,
                    text: "加载中，即将跳转...",
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            }
            console.log(params.payType)
            orderInfoPay(params).then((res) => {
                if (res.data.code==200) {
                    if(payType!=1 && payType!=2){
                        this.message('success',res.data.message,3000)
                        setTimeout(()=>{
                            window.location.reload(); //刷新页面
                        },1000)
                    }else{
                        let data=res.data.data
                        if(payType==1){ // 微信支付
                            this.dialogVisiblePay=true
                            setTimeout(()=>{
                                this.creatQrCode(data)
                            },200)

                            this.timeInterval=setInterval(()=>{
                                this.countdown--
                                this.getorderNumDetail()  // 轮询获取支付状态

                                console.log(this.countdown)
                                if(this.countdown==0){
                                    this.closePay()
                                    this.message('warning','支付超时请重新支付')
                                    clearInterval(this.timeInterval)
                                }
                            },1000)
                        }else{ // 支付宝支付
                            // this.dialogVisiblePay=true
                            setTimeout(()=>{
                                this.aliPayInfo=data
                                // 防抖避免重复支付
                                this.$nextTick(() => {
                                    // 提交支付表单
                                    this.$refs.alipayWap.children[0].submit();
                                    setTimeout(() => {
                                        loading.close();
                                        // this.toPayFlag = false;
                                    }, 500);
                                });
                            },200)
                        }
                    }

                }else{
                    loading.close();
                    this.message('error',res.data.mag)
                }

            }).catch(error=>{
                loading.close();
                console.log(error)
                if(error.response.data.code !=200){
                    this.message('error',error.response.data.message)
                }
            })
        },

        getuserDetail(){ // 获取用户信息
            console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
            let userId=JSON.parse(localStorage.getItem('userInfo')).userId
            userDetail({userId:userId}).then((res) => {
                if (res.data.code==200) {
                    localStorage.setItem('customInfo', JSON.stringify(res.data.data)); // 登录用户信息
                    localStorage.setItem('customId', res.data.data.id)
                    this.moneyTotal=JSON.parse(localStorage.getItem('customInfo')).moneyTotal || 0

                }

            })
        },
    },
}
</script>

<style scoped>

</style>
